import "./Header.css";
import { Link } from "react-router";
import Nav from "../Nav/Nav";
import Image from "../../components/Image/Image";
import LogoImg from "../../assets/Icons/logo.png";

function Header({ page }) {
    return (
        <header className="header">
            <div className="header__content">
                <Link to="/" className="link header__link">
                    <Image src={LogoImg} alt="BIKST" type="logo" />
                </Link>
                <Nav page={page} />
            </div>
        </header>
    );
}

export default Header;
