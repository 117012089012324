import "./List.css";

function List({ items = [] }) {
    return (
        <ul className="list">
            {items.map((item, idx) => {
                return <li key={idx}>{item}</li>;
            })}
        </ul>
    );
}

export default List;
