function PolicySection({ children, title }) {
    return (
        <section className="subsection">
            <h2 className="subsection__headline">{title}</h2>
            {children}
        </section>
    );
}

export default PolicySection;
