import { Routes, Route } from "react-router";
import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";

import Home from "./views/Home/Home";
import PrivacyPolicy from "./views/PrivacyPolicy/PrivacyPolicy";
import NoMatch from "./views/NoMatch/NoMatch";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Aside from "./components/Aside/Aside";

function App() {
    const [page, setPage] = useState("BIKST");

    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA_ID, {
            testMode: process.env.NODE_ENV === "test",
        });
    }, []);

    return (
        <div className="app">
            <Header page={page} />
            <main className="main">
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={<Home setPage={setPage} />}
                    />
                    <Route
                        path="/polityka-prywatnosci"
                        element={<PrivacyPolicy />}
                    />
                    <Route path="*" element={<NoMatch />} />
                </Routes>
            </main>
            <Footer />
            <Aside />
        </div>
    );
}

export default App;
