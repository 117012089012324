import "./Card.css";
import Image from "../../components/Image/Image";
import List from "../../components/List/List";

function Card({ title, features = [], img, type, id, badge }) {
    const types = [
        {
            name: "main",
            card: "card card--main",
            imgContainer: "card__img--main",
            img: "img img--card img--card-main",
            cardSection: "card__section",
        },
        {
            name: "wide",
            card: "card card--wide",
            imgContainer: "card__img--wide",
            img: "img img--card img--card-wide",
            cardSection: "card__section card__section--wide",
        },
    ];
    const currType = type === types[0].name ? types[0] : types[1];
    const badgeView = badge ? (
        <div className={`card__badge ${badge.style}`}>
            <p className="p">{badge.name}</p>
        </div>
    ) : (
        ""
    );

    return (
        <section className={`${currType.card} card--${id}`}>
            <div className={currType.imgContainer}>
                <Image src={img} type={currType.img} />
            </div>
            {badgeView}
            <div className={currType.cardSection}>
                <h3 className="subsection__headline">{title}</h3>
                <List items={features} />
            </div>
        </section>
    );
}

export default Card;
