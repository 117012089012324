import "./Aside.css";
import { Link } from "react-router";
import { useEffect, useState } from "react";

function Aside() {
    const [visible, setVisible] = useState(false);

    const acceptCookies = () => {
        localStorage.setItem("useCookies", "true");
        setVisible(false);
    };

    useEffect(() => {
        const storedPreference = localStorage.getItem("useCookies");
        storedPreference ? setVisible(false) : setVisible(true);
    }, [visible, setVisible]);

    return (
        <aside className={visible ? "aside" : "aside aside--hidden"}>
            <p className="p p--spaced">
                Ta strona wykorzystuje pliki cookies. Kontynuowanie korzystanie
                ze strony, bez wprowadzenia stosownych zmian w ustawieniach
                przeglądarki jest równoznaczne z wyrażeniem zgody na
                przetwarzanie danych osobowych. Szczegółowe informacje zostały
                umieszczone w{" "}
                <Link className="link link--visible" to="/polityka-prywatnosci">
                    polityce prywatności serwisu
                </Link>
                .
            </p>
            <button className="button" onClick={acceptCookies}>
                OK
            </button>
        </aside>
    );
}

export default Aside;
