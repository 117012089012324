import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "./utils/index.css";

import App from "./App";
import "./utils/media.css";
import reportWebVitals from "./reportWebVitals";
import ScrollTo from "./components/ScrollTo/ScrollTo";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollTo />
            <App />
        </BrowserRouter>
    </React.StrictMode>,
);

reportWebVitals();

if (process.env.NODE_ENV !== "development") {
    console.log = () => {};
}
