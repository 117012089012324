import "./Contact.css";
import Image from "../../components/Image/Image";
import React, { forwardRef } from "react";

import EmailImg from "../../assets/Icons/alternate-email.svg";
import CallImg from "../../assets/Icons/call.svg";
import AddresstImg from "../../assets/Icons/pin.svg";

const contact = forwardRef((_, ref) => {
    const contactInfo = [
        {
            img: EmailImg,
            key: "E-mail",
            value: "bikst.kontakt@outlook.com",
            link: "mailto:bikst.kontakt@outlook.com",
        },
        {
            img: CallImg,
            key: "Telefon",
            value: "+48 572 414 314",
            link: "tel:+48572414314",
        },
        {
            img: AddresstImg,
            key: "Adres",
            value: "ul. Przemysłowa 98, 08-500 Ryki",
            link: "https://maps.google.com/maps?ll=51.602346,21.921187&z=11&t=m&hl=pl&gl=PL&mapclient=embed&q=Przemys%C5%82owa%2098%2008-500%20Ryki",
        },
    ];

    return (
        <section className="section contact" id="kontakt" ref={ref}>
            <div className="contact__content">
                <h2 className="section__headline">Kontakt</h2>
                <ul className="contact-list">
                    {contactInfo.map((elem) => {
                        return (
                            <li key={elem.key} className="contact-list__li">
                                <Image
                                    src={elem.img}
                                    type="icon contact-list__img"
                                />
                                <div className="contact-list__section">
                                    <p className="subsection__headline">
                                        {elem.key}
                                    </p>
                                    <a href={elem.link} className="link">
                                        {elem.value}
                                    </a>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className="contact__content">
                <iframe
                    title="map"
                    className="contact__map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55652.649021499725!2d21.923492766998596!3d51.62998174085163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47189ef474eb2cfb%3A0x6af374e29471e0ef!2sPrzemys%C5%82owa%2098%2C%2008-500%20Ryki!5e0!3m2!1spl!2spl!4v1657547233163!5m2!1spl!2spl"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </section>
    );
});

export default contact;
