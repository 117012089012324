import React, { forwardRef } from "react";
import Image from "../../components/Image/Image";

const start = forwardRef((_, ref) => {
    return (
        <section className="section start" id="start" ref={ref}>
            <h1 className="display-title start__headline">
                Wykończenia wnętrz i malowanie
            </h1>
            <p className="p start__p">
                Oferujemy wysokiej jakości usługi w zakresie malowania,
                układania płytek oraz mniejszych prac wykończeniowych w
                atrakcyjnych cenach. Wycena jest zawsze gratis.
            </p>
            <Image
                src={process.env.PUBLIC_URL + "/start-img.webp"}
                type="img img--start"
            />
            <a className="button start__button" href="/#oferta">
                Zobacz więcej
            </a>
        </section>
    );
});

export default start;
